import React, { useState } from "react";
import { Accordion, Container } from 'react-bootstrap'



const FaqSection = () => {


  const [activeId, setActiveId] = useState('');

  function toggleActive(id) {
    if (activeId === id) {
      setActiveId(null);
    } else {
      setActiveId(id);
    }
  }

  return (
    <section className="faq pt-100">

      <Container>
        <div className="text-center">
          
          <h4 className="heading-h2"><span className="heading-h3 bluecolor">FAQ</span>
            Frequently Asked Questions</h4>
        </div>

        <Accordion defaultActiveKey={activeId}>
            <div className={activeId === '0' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">

                <Accordion.Toggle onClick={() => toggleActive('0')} className="panel-toggle" variant="link" eventKey="0">
                What exactly is a Uniswap clone script?
                </Accordion.Toggle>
                
              </div>

              <Accordion.Collapse eventKey="0">
                <div className="panel-body card-body">The Uniswap clone script is a ready-to-launch DeFi exchange software that helps you to create and deploy a feature-packed DeFi exchange like Uniswap. Just like the Uniswap platform, this clone script is also based on the Ethereum blockchain network. In other words, we can say it’s a replica of UniSwap. This clone script is 100% decentralized and holds all the existing features of UniSwap.
                </div>
              </Accordion.Collapse>
            </div>
            <div className={activeId === '1' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('1')} className="panel-toggle" variant="link" eventKey="1">
                Once I purchase the Uniswap clone script from you, will I be able to customize it?
                </Accordion.Toggle>
                
              </div>

              <Accordion.Collapse eventKey="1">
                <div className="panel-body card-body">Yes, you can completely optimize the script as per your business needs. You can have your own logo and theme. Besides, you can also change the back-end functionality, features, visuals, and more. When you source information through external APIs, you can choose your own APIs.
                </div>
              </Accordion.Collapse>
            </div>
            <div className={activeId === '2' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('2')} className="panel-toggle" variant="link" eventKey="2">
                Is your Uniswap clone script completely secure?
                </Accordion.Toggle>
                
              </div>

              <Accordion.Collapse eventKey="2">
                <div className="panel-body card-body">Our first-class Uniswap clone software is devised by a team of well-trained blockchain experts. This clone script comes with contract-based security, end-to-end encryption, secure wallet connection, and other tightened security protocols. 
                </div>
              </Accordion.Collapse>
            </div>
            <div className={activeId === '3' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('3')} className="panel-toggle" variant="link" eventKey="3">
                What are the Features of the ready-made Uniswap clone software?
                </Accordion.Toggle>
              </div>

              <Accordion.Collapse eventKey="3">
                <div className="panel-body card-body">Our exclusive UniSwap clone script is loaded with trending features such as Automated Market Maker, staking, custom pools, multi-lingual assistance, bug bounty rewards system, and more. Besides, you can also modify these features and enable extra add-on modules as per your business concepts.
                </div>
              </Accordion.Collapse>
            </div>
            <div className={activeId === '4' ? 'panel-wrap active-panel card mb-0' : 'panel-wrap card mb-0'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('4')} className="panel-toggle" variant="link" eventKey="4">
                How long does it take to deploy an exchange using the Uniswap clone script?
                </Accordion.Toggle>
                
              </div>

              <Accordion.Collapse eventKey="4">
                <div className="panel-body card-body">Our premier Uniswap clone script is ready to be used out of the box, but this is not how a typical business works. Since you plan to manage a DeFi exchange, you would want it to be completely customized and branded according to your business identity. You might also have a plan to make some fundamental changes in the code of the Uniswap clone script itself and set up additional features. All these need to be considered when you decide how much time it is going to take. Tentatively, it can take anywhere between 7 to 10 days to deploy a fully functional exchange.
                </div>
              </Accordion.Collapse>
            </div>
        </Accordion>
      </Container>

    </section>
  )
}

export default FaqSection