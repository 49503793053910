import React from 'react'


const SecurityofOur = () => {

  return (
    <section className="security pt-100 mb-0 pb-0">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12 col-12  text-center">

            <h3 className="heading-h2"><span className="heading-h3"><span className="bluecolor">Security Features</span> of </span>
              Our UniSwap Clone Software</h3>
            <p className="pharagraph head">Being built on one of the most secure blockchain architectures, our Uniswap clone script delivers unmatched security for your platform.</p>
          </div>
        </div>
        <div className="binanceflexbox mt-0" >
          <div className="binanceboxt card">
            <div className="binancecont">
              <div className="svgback">
                <svg id="Contract-based_security" data-name="Contract-based security" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="39.454" height="39.456" viewBox="0 0 39.454 39.456">
                  <defs>
                    <clipPath id="clip-path">
                      <rect id="Rectangle_8911" data-name="Rectangle 8911" width="39.454" height="39.456" fill="gray" />
                    </clipPath>
                  </defs>
                  <g id="Group_28390" data-name="Group 28390" clipPath="url(#clip-path)">
                    <path id="Path_22358" data-name="Path 22358" d="M368.151,400.368a3.888,3.888,0,0,1-1.907-.919,3.432,3.432,0,0,1-1.043-3.464.594.594,0,0,0-.125-.62q-.744-.832-1.438-1.708a.758.758,0,0,0-.687-.306c-.87.017-1.741.006-2.636.006v-2.3c1.256,0,2.5-.031,3.738.023a1.407,1.407,0,0,1,.823.5c.665.728,1.284,1.5,1.929,2.262a3.331,3.331,0,0,1,2.8-.292,3.4,3.4,0,0,1,2.325,2.637c.019.086.046.171.069.257v.77a1.159,1.159,0,0,0-.06.179,3.584,3.584,0,0,1-1.211,2.148,4.113,4.113,0,0,1-1.81.832Z" transform="translate(-332.55 -360.913)" fill="gray" />
                    <path id="Path_22359" data-name="Path 22359" d="M372.027,184.932a4.205,4.205,0,0,1-.82,1.837,3.4,3.4,0,0,1-3.684,1.055.545.545,0,0,0-.667.144c-.632.638-1.3,1.24-1.94,1.872a1.579,1.579,0,0,1-1.2.492c-1.115-.018-2.23-.006-3.371-.006v-2.311c.877,0,1.746-.018,2.614.009a1.106,1.106,0,0,0,.909-.371c.435-.454.9-.883,1.363-1.305a.413.413,0,0,0,.126-.55,3.267,3.267,0,0,1,.715-3.632,3.233,3.233,0,0,1,3.378-.946,3.342,3.342,0,0,1,2.515,2.724c.016.074.043.146.065.219Z" transform="translate(-332.574 -167.126)" fill="gray" />
                    <path id="Path_22360" data-name="Path 22360" d="M371.974,304.978a4.265,4.265,0,0,1-.82,1.836,3.436,3.436,0,0,1-5.721-.69.575.575,0,0,0-.633-.384c-1.36.018-2.721.008-4.081.008h-.435v-2.311h.4c1.425,0,2.849,0,4.274,0a.373.373,0,0,0,.41-.242,3.442,3.442,0,0,1,6.554.866.81.81,0,0,0,.055.142Z" transform="translate(-332.52 -277.927)" fill="gray" />
                    <path id="Path_22361" data-name="Path 22361" d="M12.71,211.038q4.545,0,9.09,0a3.489,3.489,0,0,1,2.937,1.406,3.42,3.42,0,0,1,.692,2.119c.006,4.583.016,9.167,0,13.75a3.432,3.432,0,0,1-3.511,3.519q-9.186.027-18.372,0a3.441,3.441,0,0,1-3.53-3.54q-.024-6.856,0-13.712A3.458,3.458,0,0,1,3.62,211.04c3.03-.009,6.06,0,9.09,0m1.165,13.327c0-.526.019-1.054-.007-1.579a.513.513,0,0,1,.357-.573,3.43,3.43,0,0,0,1.356-5.033,3.461,3.461,0,0,0-6.317,1.769,3.416,3.416,0,0,0,2.02,3.3.42.42,0,0,1,.284.462c-.012,1.091-.011,2.182,0,3.274a1.156,1.156,0,1,0,2.308,0c.007-.539,0-1.078,0-1.618" transform="translate(0 -194.773)" fill="gray" />
                    <path id="Path_22362" data-name="Path 22362" d="M44.178,13.926c.072-2.093,0-4.172.249-6.212a8.406,8.406,0,0,1,4.028-6.185,9.133,9.133,0,0,1,12.026,1.7,8.945,8.945,0,0,1,2.251,5.954c.022,1.515.005,3.03.005,4.545,0,.062-.008.123-.013.205H58.115c0-.127,0-.261,0-.4,0-1.451.018-2.9-.006-4.353a4.616,4.616,0,0,0-9.228-.1c-.034,1.463-.009,2.927-.011,4.391,0,.14,0,.28,0,.448Z" transform="translate(-40.774 0)" fill="gray" />
                    <path id="Path_22363" data-name="Path 22363" d="M151.272,301.078a1.153,1.153,0,1,1-1.184,1.121,1.165,1.165,0,0,1,1.184-1.121" transform="translate(-138.521 -277.876)" fill="gray" />
                  </g>
                </svg>
              </div>
              <div className="card-body">
                <h5 className="card-title">Contract-based security</h5> </div>
            </div>
          </div>
          <div className="binanceboxt card">
            <div className="binancecont">
              <div className="svgback">
                <svg id="Complete_decentralization" data-name="Complete  decentralization" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="39.455" height="39.453" viewBox="0 0 39.455 39.453">
                  <defs>
                    <clipPath id="clip-path">
                      <rect id="Rectangle_8912" data-name="Rectangle 8912" width="39.455" height="39.453" fill="gray" />
                    </clipPath>
                  </defs>
                  <g id="Group_28392" data-name="Group 28392" clipPath="url(#clip-path)">
                    <path id="Path_22364" data-name="Path 22364" d="M155.425,163.156a7.75,7.75,0,1,1,7.745,7.766,7.752,7.752,0,0,1-7.745-7.766" transform="translate(-143.448 -143.446)" fill="gray" />
                    <path id="Path_22365" data-name="Path 22365" d="M15.13,53.128l-2.283,1.654c-.631-.873-1.271-1.764-1.917-2.65-.362-.5-.639-1.169-1.13-1.425a4.29,4.29,0,0,0-1.776-.065,1.017,1.017,0,0,1-.132,0,1.486,1.486,0,0,0-1.639.758,3.334,3.334,0,0,1-3.969,1.123,3.52,3.52,0,0,1,.392-6.709,3.464,3.464,0,0,1,3.856,1.614.7.7,0,0,0,.717.4c1.085-.021,2.171-.005,3.257-.009a1.315,1.315,0,0,1,1.127.494c1.181,1.6,2.344,3.219,3.5,4.812" transform="translate(-0.007 -42.187)" fill="gray" />
                    <path id="Path_22366" data-name="Path 22366" d="M12.926,348.642l2.273,1.647c-1.146,1.582-2.3,3.187-3.469,4.781a1.349,1.349,0,0,1-1.158.524c-1.115,0-2.23.008-3.344-.007a.558.558,0,0,0-.579.331,3.474,3.474,0,0,1-4.322,1.544,3.517,3.517,0,0,1,1.5-6.789,3.377,3.377,0,0,1,2.842,1.8.5.5,0,0,0,.507.3c.807-.015,1.614.011,2.42-.018a.774.774,0,0,0,.524-.273c.859-1.152,1.693-2.322,2.535-3.486.093-.128.192-.253.274-.361" transform="translate(-0.077 -321.775)" fill="gray" />
                    <path id="Path_22367" data-name="Path 22367" d="M318.085,54.772l-2.272-1.642c.342-.475.7-.973,1.056-1.469.755-1.046,1.516-2.088,2.264-3.139a1.559,1.559,0,0,1,1.382-.7c1.085.014,2.171,0,3.256.009a.556.556,0,0,0,.583-.324,3.473,3.473,0,0,1,4.318-1.554,3.517,3.517,0,0,1-1.48,6.793,3.358,3.358,0,0,1-2.823-1.761.591.591,0,0,0-.615-.348q-1.143.028-2.288,0a.642.642,0,0,0-.613.311c-.82,1.162-1.66,2.309-2.494,3.461-.093.129-.191.254-.276.365" transform="translate(-291.476 -42.187)" fill="gray" />
                    <path id="Path_22368" data-name="Path 22368" d="M315.843,350.284l2.266-1.642c.071.091.169.211.259.337.833,1.152,1.672,2.3,2.493,3.462a.678.678,0,0,0,.642.342c.777-.024,1.555-.02,2.332,0a.522.522,0,0,0,.541-.319,3.514,3.514,0,1,1,2.9,5.235,3.377,3.377,0,0,1-2.895-1.792.531.531,0,0,0-.542-.318c-1.129.016-2.259.005-3.388.007a1.413,1.413,0,0,1-1.21-.6q-1.6-2.207-3.194-4.421c-.069-.095-.134-.192-.2-.286" transform="translate(-291.504 -321.775)" fill="gray" />
                    <path id="Path_22369" data-name="Path 22369" d="M215.12,9.909H212.3c0-.6-.036-1.242.009-1.877A1.734,1.734,0,0,0,211.49,6.2a3.308,3.308,0,0,1-1.045-3.985,3.52,3.52,0,0,1,6.678.43,3.452,3.452,0,0,1-1.583,3.864.754.754,0,0,0-.426.792c.02.875.006,1.752.006,2.61" transform="translate(-193.985 -0.001)" fill="gray" />
                    <path id="Path_22370" data-name="Path 22370" d="M9.908,212.373v2.788a2.705,2.705,0,0,1-.29.029c-.542,0-1.088.039-1.626-.007A1.7,1.7,0,0,0,6.2,216a3.319,3.319,0,0,1-3.986,1.047,3.52,3.52,0,0,1,.423-6.679A3.455,3.455,0,0,1,6.5,211.95a.754.754,0,0,0,.79.431c.875-.022,1.752-.007,2.613-.007" transform="translate(0 -194.056)" fill="gray" />
                    <path id="Path_22371" data-name="Path 22371" d="M383.4,215.026v-2.788a2.637,2.637,0,0,1,.29-.029c.484,0,.969-.031,1.451,0a1.924,1.924,0,0,0,2.028-.878,3.277,3.277,0,0,1,4-.958,3.521,3.521,0,0,1-.589,6.668,3.454,3.454,0,0,1-3.8-1.633.691.691,0,0,0-.722-.394c-.89.021-1.781.007-2.658.007" transform="translate(-353.857 -193.89)" fill="gray" />
                    <path id="Path_22372" data-name="Path 22372" d="M212.378,383.4H215.2c0,.9.012,1.819-.007,2.739a.586.586,0,0,0,.354.608,3.486,3.486,0,0,1,1.642,3.957,3.52,3.52,0,0,1-6.792.02,3.475,3.475,0,0,1,1.621-3.964.622.622,0,0,0,.373-.644c-.027-.905-.009-1.812-.009-2.715" transform="translate(-194.06 -353.857)" fill="gray" />
                  </g>
                </svg>
              </div>
              <div className="card-body">
                <h5 className="card-title">Complete decentralization</h5>
              </div>
            </div>
          </div>
          <div className="binanceboxt card">
            <div className="binancecont">
              <div className="svgback">
                <svg id="Tokens_secured_by_smart_contracts" data-name="Tokens secured by  smart contracts" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="39.455" height="35.23" viewBox="0 0 39.455 35.23">
                  <defs>
                    <clipPath id="clip-path">
                      <rect id="Rectangle_8913" data-name="Rectangle 8913" width="39.455" height="35.23" fill="gray" />
                    </clipPath>
                  </defs>
                  <g id="Group_28394" data-name="Group 28394" clipPath="url(#clip-path)">
                    <path id="Path_22373" data-name="Path 22373" d="M225.037,35.782v.543q0,10.458,0,20.916c0,.908-.179,1.091-1.068,1.091H202.129c-.864,0-1.051-.189-1.051-1.063q0-14.443,0-28.886c0-.87.188-1.058,1.056-1.058h14.448v.5q0,3.17,0,6.341a1.441,1.441,0,0,0,1.63,1.62q3.148,0,6.3,0ZM213.012,41.42q3.566,0,7.132,0c.558,0,.663-.108.665-.67,0-.528-.006-1.057,0-1.585.006-.384-.164-.565-.555-.565q-7.2,0-14.4,0c-.389,0-.563.176-.557.562.008.514,0,1.027,0,1.541,0,.625.091.717.706.717h7m.06,5.638q3.544,0,7.088,0c.531,0,.646-.116.649-.642s0-1.027,0-1.541-.116-.634-.656-.635q-1.717,0-3.434,0H205.977c-.564,0-.668.1-.671.664q0,.748,0,1.5c0,.552.109.657.678.657q3.544,0,7.088,0m-4.229-11.276c.983,0,1.966,0,2.948,0,.385,0,.568-.168.563-.556-.007-.572-.006-1.144,0-1.716,0-.36-.154-.549-.524-.549q-2.992,0-5.985,0c-.364,0-.542.166-.54.533,0,.572.009,1.144,0,1.716-.007.414.188.578.591.574.983-.01,1.966,0,2.948,0m8.4,16.913c.983,0,1.966,0,2.948,0,.5,0,.615-.123.619-.627,0-.543-.005-1.086,0-1.628.005-.384-.165-.565-.555-.564q-2.97.006-5.941,0c-.39,0-.561.179-.555.564.008.543,0,1.085,0,1.628,0,.506.119.626.618.628.953,0,1.907,0,2.86,0" transform="translate(-185.582 -25.22)" fill="gray" />
                    <path id="Path_22374" data-name="Path 22374" d="M30.1,7.743v2.8c-.15.007-.291.02-.433.02-1.159,0-2.318-.023-3.476.009a1.784,1.784,0,0,1-1.633-.831c-.608-.862-1.251-1.7-1.9-2.53A.635.635,0,0,0,22.16,7a3.52,3.52,0,1,1,2.922-4.316,3.341,3.341,0,0,1-.236,2.271A.449.449,0,0,0,24.9,5.5c.507.646.977,1.32,1.492,1.959a.8.8,0,0,0,.532.268c1.04.025,2.08.012,3.169.012" transform="translate(-16.732 0.001)" fill="gray" />
                    <path id="Path_22375" data-name="Path 22375" d="M30.141,319.9v2.819c-1.008,0-2,.019-2.991-.01a.861.861,0,0,0-.831.416c-.423.615-.88,1.209-1.344,1.795a.521.521,0,0,0-.067.625,3.508,3.508,0,1,1-2.7-2.083.632.632,0,0,0,.493-.208c.686-.877,1.352-1.771,2.011-2.669A1.589,1.589,0,0,1,26.1,319.9c1.333.015,2.666,0,4.043,0" transform="translate(-16.777 -295.247)" fill="gray" />
                    <path id="Path_22376" data-name="Path 22376" d="M13.361,184.96v2.819h-.49c-1.907,0-3.815.005-5.722-.006a.562.562,0,0,0-.582.326,3.514,3.514,0,1,1-2.877-5.244,3.353,3.353,0,0,1,2.866,1.765.6.6,0,0,0,.616.345c1.893-.011,3.785-.006,5.678-.006h.512" transform="translate(0 -168.76)" fill="gray" />
                    <path id="Path_22377" data-name="Path 22377" d="M429.689,38.1l5.522,5.512h-1.773c-.981,0-1.963,0-2.944,0-.508,0-.8-.256-.8-.73-.011-1.6,0-3.192,0-4.779" transform="translate(-396.575 -35.168)" fill="gray" />
                  </g>
                </svg>
              </div>
              <div className="card-body">
                <h5 className="card-title">Tokens secured by smart contracts</h5>
              </div></div>
          </div>
          <div className="binanceboxt card">
            <div className="binancecont">
              <div className="svgback">
                <svg id="Secure_wallet_integration" data-name="Secure wallet integration" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="39.455" height="35.469" viewBox="0 0 39.455 35.469">
                  <defs>
                    <clipPath id="clip-path">
                      <rect id="Rectangle_8914" data-name="Rectangle 8914" width="39.455" height="35.469" fill="gray" />
                    </clipPath>
                  </defs>
                  <g id="Group_28396" data-name="Group 28396" clipPath="url(#clip-path)">
                    <path id="Path_22378" data-name="Path 22378" d="M0,103.378V80.106a1.37,1.37,0,0,1,1.468-.922q18.262.015,36.524.007a3.661,3.661,0,0,1,.385.008,1.1,1.1,0,0,1,1.04.927c.058.67.014,1.348.014,2.051h-.486q-6.338,0-12.675-.005a1.679,1.679,0,0,0-1.159.415c-.841.716-1.7,1.41-2.552,2.111-.581.478-1.164.954-1.738,1.425-.178-.135-.315-.252-.466-.349A3.467,3.467,0,0,0,14.963,88.5a3.46,3.46,0,1,0,6.916.125.688.688,0,0,1,.282-.614c1.346-1.091,2.675-2.205,4.024-3.294a1.059,1.059,0,0,1,.615-.22q6.126-.019,12.252-.009c.124,0,.249.011.374.017V86.8h-.488c-3.121,0-6.241,0-9.362,0A3.537,3.537,0,0,0,25.9,90.5c0,1.349,0,2.7,0,4.045a3.578,3.578,0,0,0,3.837,3.818h9.691v2.3c-.095.005-.158.012-.221.012q-4.874,0-9.747,0a1.761,1.761,0,0,1-.379-.049q-3.471-.78-6.943-1.562a.388.388,0,0,1-.342-.338,3.467,3.467,0,1,0-.556,2.8.414.414,0,0,1,.507-.192c1.723.4,3.446.8,5.176,1.178a13.918,13.918,0,0,0,2.642.455c3.145.055,6.292.022,9.439.022h.456c0,1.463,0,2.862,0,4.261a1.168,1.168,0,0,1-1.306,1.306q-16.124,0-32.247-.006a6.368,6.368,0,0,1-1.447-.167,5.679,5.679,0,0,1-3.151-2.01,3.523,3.523,0,0,0,.352-.272q3.695-3.688,7.389-7.377a1.654,1.654,0,0,0,.521-1.266c-.014-2.748,0-5.5-.012-8.245a.48.48,0,0,1,.317-.517,3.326,3.326,0,0,0,1.986-2.861,3.445,3.445,0,0,0-2.863-3.682A3.484,3.484,0,0,0,5.1,84.511a3.447,3.447,0,0,0,1.885,4.2.392.392,0,0,1,.266.43c-.006,2.594,0,5.188-.013,7.782a.831.831,0,0,1-.217.528Q3.764,100.738.487,104c-.075.075-.162.139-.274.234L0,103.378" transform="translate(0 -73.082)" fill="gray" />
                    <path id="Path_22379" data-name="Path 22379" d="M4.263,3.811A11.753,11.753,0,0,1,4.886,2.64,5.6,5.6,0,0,1,9.713.009Q23.639-.013,37.565,0a1.128,1.128,0,0,1,1.228,1.185c.016.856,0,1.712,0,2.622Z" transform="translate(-3.934 0)" fill="gray" />
                    <path id="Path_22380" data-name="Path 22380" d="M377.249,208.02v6.935H367.5a1.289,1.289,0,0,1-1.484-1.481q0-2.021,0-4.042a1.249,1.249,0,0,1,1.389-1.411q4.735,0,9.469,0Zm-7.731,2.314a1.158,1.158,0,0,0-1.184,1.11,1.154,1.154,0,1,0,2.308.057,1.147,1.147,0,0,0-1.123-1.167" transform="translate(-337.813 -191.989)" fill="gray" />
                    <path id="Path_22381" data-name="Path 22381" d="M225.226,187.05a1.147,1.147,0,0,1,1.131,1.16,1.154,1.154,0,1,1-2.308-.042,1.157,1.157,0,0,1,1.177-1.117" transform="translate(-206.783 -172.636)" fill="gray" />
                    <path id="Path_22382" data-name="Path 22382" d="M225.212,328.05a1.146,1.146,0,0,1,1.145,1.146,1.154,1.154,0,1,1-2.308-.014,1.158,1.158,0,0,1,1.164-1.132" transform="translate(-206.783 -302.77)" fill="gray" />
                    <path id="Path_22383" data-name="Path 22383" d="M95.2,147.046a1.15,1.15,0,0,1,1.137,1.155,1.154,1.154,0,1,1-2.309-.032,1.154,1.154,0,0,1,1.171-1.123" transform="translate(-86.778 -135.714)" fill="gray" />
                  </g>
                </svg>
              </div>
              <div className="card-body">
                <h5 className="card-title">Secure Wallet integration
                </h5>
              </div> </div>
          </div>
          <div className="binanceboxt card">
            <div className="binancecont">
              <div className="svgback">
                <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="39.446" height="39.456" viewBox="0 0 39.446 39.456">
                  <defs>
                    <clipPath id="clip-path">
                      <rect id="Rectangle_8915" data-name="Rectangle 8915" width="39.446" height="39.456" fill="gray" />
                    </clipPath>
                  </defs>
                  <g id="Multi-layered_security_through_add-ons" data-name="Multi-layered security  through add-ons" transform="translate(0)">
                    <g id="Group_28398" data-name="Group 28398" transform="translate(0)" clipPath="url(#clip-path)">
                      <path id="Path_22384" data-name="Path 22384" d="M0,4.863C.623,3.27,1.99,2.514,3.425,1.857A21.192,21.192,0,0,1,10.112.183,31.179,31.179,0,0,1,18.9.471a17.078,17.078,0,0,1,5.592,1.858A8.354,8.354,0,0,1,26.174,3.59a2.117,2.117,0,0,1,.018,3.315,8.544,8.544,0,0,1-3,1.9,23.183,23.183,0,0,1-7.22,1.59,30.2,30.2,0,0,1-9.032-.605A15.206,15.206,0,0,1,1.883,7.8,4.119,4.119,0,0,1,0,5.556Z" transform="translate(0 -0.001)" fill="gray" />
                      <path id="Path_22385" data-name="Path 22385" d="M0,381.028c.671.369,1.327.771,2.017,1.1a22.767,22.767,0,0,0,6.863,1.9,34.615,34.615,0,0,0,6.953.228,29.082,29.082,0,0,0,6.469-1.108c.121-.036.244-.069.386-.108a17.365,17.365,0,0,0,3.791,4.146,6.044,6.044,0,0,1-1.973,1.614,16.915,16.915,0,0,1-5.6,1.845c-1.163.189-2.342.277-3.513.412-.1.012-.2.04-.3.061H11.942c-.113-.021-.224-.048-.338-.061-1.17-.135-2.349-.217-3.509-.411a16.7,16.7,0,0,1-5.858-1.99A4.419,4.419,0,0,1,0,386.19v-5.162" transform="translate(0 -351.665)" fill="gray" />
                      <path id="Path_22386" data-name="Path 22386" d="M0,130.916c.556.311,1.1.643,1.67.928a22.842,22.842,0,0,0,7.289,2.077,33.786,33.786,0,0,0,7.684.152,22.813,22.813,0,0,0,9.644-2.762c.111-.065.218-.135.327-.2l.329-.206v2.762H25.588a1.737,1.737,0,0,0-1.7,1.435,3.581,3.581,0,0,1-2.776,3.183,1.985,1.985,0,0,0-1.558,1.855c-.01.1-.172.232-.283.254a29.991,29.991,0,0,1-7.2.512,25.739,25.739,0,0,1-7.29-1.278,11.97,11.97,0,0,1-3.289-1.647A4.135,4.135,0,0,1,0,136.078v-5.162" transform="translate(0 -120.814)" fill="gray" />
                      <path id="Path_22387" data-name="Path 22387" d="M0,256.055c.59.33,1.167.687,1.772.986a22.286,22.286,0,0,0,6.612,1.942,34.258,34.258,0,0,0,8.367.223c.968-.092,1.931-.24,2.943-.368a21.7,21.7,0,0,0,1.6,6.249c-.758.172-1.483.37-2.22.5a30.446,30.446,0,0,1-13.086-.445,12.927,12.927,0,0,1-4.559-2.066A4.113,4.113,0,0,1,0,261.217v-5.162" transform="translate(0 -236.323)" fill="gray" />
                      <path id="Path_22388" data-name="Path 22388" d="M300.258,204.355c1.335,0,2.671,0,4.006,0a.346.346,0,0,1,.4.29,6.177,6.177,0,0,0,3.766,4c.18.074.294.135.283.374A16.8,16.8,0,0,1,302.7,221.7c-.656.549-1.4.993-2.1,1.489a.745.745,0,0,1-.857.041,14.016,14.016,0,0,1-7.043-8.423,19.859,19.859,0,0,1-.946-5.712c-.008-.272.091-.366.325-.463a6.149,6.149,0,0,0,3.725-3.994.348.348,0,0,1,.408-.286c1.348.008,2.7,0,4.044,0m-5.465,9.321,2.651,3.112a1.481,1.481,0,0,0,2.389.087q2.191-2.243,4.372-4.5L305.541,211l-1.966-2.153-4.831,4.964-1.735-2.026-2.216,1.888" transform="translate(-269.272 -188.603)" fill="gray" />
                    </g>
                  </g>
                </svg>
              </div>
              <div className="card-body">
                <h5 className="card-title">Multilayered security through add-ons</h5> </div>
            </div>
          </div>
        </div>
        <div className="binanceflexbox" >
          <div className="binanceboxt card">
            <div className="binancecont">
              <div className="svgback">
                <svg id="End-to-end_encryption" data-name="End-to-end encryption" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="39.455" height="34.668" viewBox="0 0 39.455 34.668">
                  <defs>
                    <clipPath id="clip-path">
                      <rect id="Rectangle_8917" data-name="Rectangle 8917" width="39.455" height="34.668" fill="gray" />
                    </clipPath>
                  </defs>
                  <g id="Group_28402" data-name="Group 28402" clipPath="url(#clip-path)">
                    <path id="Path_22393" data-name="Path 22393" d="M0,189.6c.13-.652.217-1.315.4-1.953a10.424,10.424,0,0,1,10.7-7.57,10.1,10.1,0,0,1,9.016,6.55.482.482,0,0,0,.523.361c4.251-.011,8.5,0,12.752-.014a1.7,1.7,0,0,1,1.3.531c1.415,1.436,2.848,2.854,4.272,4.282a1.2,1.2,0,0,1,.011,1.936q-2.163,2.169-4.331,4.331a1.2,1.2,0,0,1-1.935,0c-.435-.435-.863-.877-1.337-1.359-.439.443-.849.861-1.263,1.276-.767.768-1.34.771-2.1.008-.407-.409-.807-.826-1.228-1.258-.1.085-.184.142-.253.21-.42.416-.829.842-1.257,1.249a1.145,1.145,0,0,1-1.686,0,10.948,10.948,0,0,1-1.109-1.122,1.973,1.973,0,0,0-1.989-.829,5.052,5.052,0,0,1-1.039,0,.588.588,0,0,0-.569.282,10.048,10.048,0,0,1-7.013,4.237,10.167,10.167,0,0,1-9.213-3.421,9.92,9.92,0,0,1-2.584-5.767A1.891,1.891,0,0,0,0,191.3v-1.7m8.165-2.619a3.466,3.466,0,1,0,3.469,3.483,3.476,3.476,0,0,0-3.469-3.483" transform="translate(0 -166.175)" fill="gray" />
                    <path id="Path_22394" data-name="Path 22394" d="M0,.85C.033.8.07.75.1.7a1.17,1.17,0,0,1,1.3-.669A1.165,1.165,0,0,1,2.31,1.2c.007.474,0,.949,0,1.424q0,3.847,0,7.695a1.167,1.167,0,0,1-.916,1.213,1.171,1.171,0,0,1-1.312-.7A.971.971,0,0,0,0,10.713V.85" transform="translate(0 0)" fill="gray" />
                    <path id="Path_22395" data-name="Path 22395" d="M61.015,5.819a22.587,22.587,0,0,1,.023-2.956A3.388,3.388,0,0,1,64.666.049a3.443,3.443,0,0,1,3.2,3.269q.063,2.5,0,5.005a3.459,3.459,0,0,1-6.911-.04c-.025-.821,0-1.643,0-2.464h.064m4.555,0c0-.73,0-1.461,0-2.191a1.16,1.16,0,1,0-2.31-.01q-.005,2.21,0,4.421a1.158,1.158,0,1,0,2.309.01c.005-.743,0-1.486,0-2.23" transform="translate(-56.247 -0.038)" fill="gray" />
                    <path id="Path_22396" data-name="Path 22396" d="M307.671,5.822a22.766,22.766,0,0,1-.023,2.956,3.383,3.383,0,0,1-3.621,2.821,3.456,3.456,0,0,1-3.212-3.3q-.051-2.463,0-4.928a3.46,3.46,0,0,1,6.914-.013c.027.821,0,1.643,0,2.464h-.063m-2.244.014c0-.743,0-1.486,0-2.23a1.158,1.158,0,1,0-2.309,0q-.006,2.21,0,4.421a1.16,1.16,0,1,0,2.31,0c0-.73,0-1.461,0-2.191" transform="translate(-277.611 -0.046)" fill="gray" />
                    <path id="Path_22397" data-name="Path 22397" d="M183.286,5.829q0,2.232,0,4.463a1.164,1.164,0,1,1-2.31.018q0-4.482,0-8.965a1.164,1.164,0,1,1,2.31.021q0,2.232,0,4.463" transform="translate(-167.028 -0.046)" fill="gray" />
                    <path id="Path_22398" data-name="Path 22398" d="M243.276,5.8q0,2.212,0,4.425c0,.82-.448,1.347-1.146,1.354a1.181,1.181,0,0,1-1.165-1.334q0-4.482,0-8.965a1.158,1.158,0,1,1,2.309-.02c0,1.513,0,3.027,0,4.54" transform="translate(-222.395 -0.018)" fill="gray" />
                    <path id="Path_22399" data-name="Path 22399" d="M420.946,5.786q0-2.212,0-4.425a1.164,1.164,0,1,1,2.31-.011q0,4.482,0,8.965a1.162,1.162,0,1,1-2.31.011q0-2.27,0-4.54" transform="translate(-388.507 -0.047)" fill="gray" />
                    <path id="Path_22400" data-name="Path 22400" d="M483.253,5.8q0,2.251,0,4.5a1.164,1.164,0,1,1-2.31,0q0-4.482,0-8.965a1.162,1.162,0,1,1,2.31,0q0,2.232,0,4.463" transform="translate(-443.879 -0.045)" fill="gray" />
                    <path id="Path_22401" data-name="Path 22401" d="M93.316,301.158a1.154,1.154,0,1,1-1.159-1.14,1.168,1.168,0,0,1,1.159,1.14" transform="translate(-83.995 -276.898)" fill="gray" />
                  </g>
                </svg>
              </div>
              <div className="card-body">
                <h5 className="card-title">End-to-End encryption</h5>
              </div> </div>
          </div>
          <div className="binanceboxt card">
            <div className="binancecont">
              <div className="svgback">
                <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="39.312" height="39.263" viewBox="0 0 39.312 39.263">
                  <defs>
                    <clipPath id="clip-path">
                      <rect id="Rectangle_8918" data-name="Rectangle 8918" width="39.312" height="39.263" fill="gray" />
                    </clipPath>
                  </defs>
                  <g id="Anonymous_tradeability_through_ownership" data-name="Anonymous tradeability  through ownership" transform="translate(0)">
                    <g id="Group_28404" data-name="Group 28404" transform="translate(0)" clipPath="url(#clip-path)">
                      <path id="Path_22402" data-name="Path 22402" d="M0,331.391a1.479,1.479,0,0,1,1.271-.779,1.3,1.3,0,0,1,1.277,1.059,3.13,3.13,0,0,1,.045.571q0,5.269,0,10.538a3.22,3.22,0,0,1-.027.458,1.315,1.315,0,0,1-.926,1.111,1.334,1.334,0,0,1-1.51-.567c-.042-.063-.087-.123-.131-.185V331.391" transform="translate(0 -305.134)" fill="gray" />
                      <path id="Path_22403" data-name="Path 22403" d="M74.057,245.648c-3.127,0-6.254-.016-9.38.014a1.144,1.144,0,0,1-1.15-1.121c0-.1,0-.2,0-.307q0-8.326,0-16.653a3.679,3.679,0,0,1,1.2-2.954,3.831,3.831,0,0,1,4.415-.507A4.69,4.69,0,0,1,72,228.566c.029,1.176,0,2.353.008,3.53a3.628,3.628,0,0,0,2.165,3.506,4.413,4.413,0,0,0,1.74.374c2.191.043,4.382.022,6.574.007a.907.907,0,0,0,.534-.221c2.033-1.759,4.051-3.536,6.083-5.3a5.621,5.621,0,0,1,7.62.084,3.816,3.816,0,0,1,.119,5.468c-1.318,1.269-2.707,2.465-4.069,3.688-1.467,1.318-2.917,2.655-4.413,3.937a8.152,8.152,0,0,1-5.573,2.013c-2.909-.032-5.818-.008-8.727-.008" transform="translate(-58.625 -206.418)" fill="gray" />
                      <path id="Path_22404" data-name="Path 22404" d="M181.832,170.5c1.332,0,2.664-.01,4,0a1.967,1.967,0,0,1,2.079,2.169,1.942,1.942,0,0,1-1.551,1.728,3.261,3.261,0,0,1-.573.054q-3.938.005-7.876,0a1.99,1.99,0,0,1-2.1-1.543,1.951,1.951,0,0,1,1.575-2.362,3.862,3.862,0,0,1,.611-.047c1.281,0,2.561,0,3.842,0" transform="translate(-162.209 -157.357)" fill="gray" />
                      <path id="Path_22405" data-name="Path 22405" d="M181.781,3.928c-1.421,0-2.844.035-4.264-.01a1.929,1.929,0,0,1-1.688-2.711A1.942,1.942,0,0,1,177.754,0q3.631,0,7.261,0c.295,0,.589-.008.883,0a1.959,1.959,0,0,1,1.938,1.972,1.917,1.917,0,0,1-1.906,1.948c-1.382.031-2.766.008-4.149.008V3.928" transform="translate(-162.127 0)" fill="gray" />
                      <path id="Path_22406" data-name="Path 22406" d="M181.784,85.378c1.409,0,2.818-.038,4.225.01a1.935,1.935,0,0,1,1.59,2.937,1.844,1.844,0,0,1-1.643.969c-2.779.015-5.558.022-8.336,0a1.956,1.956,0,0,1-.023-3.91c1.395-.034,2.791-.008,4.187-.008v0" transform="translate(-162.157 -78.786)" fill="gray" />
                      <path id="Path_22407" data-name="Path 22407" d="M335.883,229.615a.867.867,0,0,1-.044-.173c.008-1.3-.049-2.613.041-3.911a4.222,4.222,0,0,1,8.285-.573c-.522.063-1.018.1-1.5.188a7.822,7.822,0,0,0-3.771,1.866q-1.344,1.176-2.692,2.347c-.093.081-.191.156-.315.257" transform="translate(-309.949 -204.683)" fill="gray" />
                    </g>
                  </g>
                </svg>
              </div>
              <div className="card-body">
                <h5 className="card-title">Traceability through ownership</h5>
              </div></div>
          </div>
          <div className="binanceboxt card">
            <div className="binancecont">
              <div className="svgback">
                <svg id="Less_Reliance_On" data-name="Less Reliance On" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="39.455" height="39.44" viewBox="0 0 39.455 39.44">
                  <defs>
                    <clipPath id="clip-path">
                      <rect id="Rectangle_8919" data-name="Rectangle 8919" width="39.455" height="39.44" fill="gray" />
                    </clipPath>
                  </defs>
                  <g id="Group_28406" data-name="Group 28406" clipPath="url(#clip-path)">
                    <path id="Path_22408" data-name="Path 22408" d="M0,410.5a22.219,22.219,0,0,1,.716-2.417,6.785,6.785,0,0,1,2.916-3.192,5.871,5.871,0,0,0,7.394-.012,7.27,7.27,0,0,1,3.53,5.306c.067.455.04.922.083,1.381a1.4,1.4,0,0,1-.851,1.552H.847A1.776,1.776,0,0,1,0,412.271V410.5" transform="translate(0 -373.677)" fill="gray" />
                    <path id="Path_22409" data-name="Path 22409" d="M322.578,412.989a1.494,1.494,0,0,1-.86-1.55,7.426,7.426,0,0,1,3.184-6.406.63.63,0,0,1,.885-.009,5.7,5.7,0,0,0,6.658-.078c.092-.067.186-.131.3-.207a7.286,7.286,0,0,1,3.548,5.32c.067.454.04.921.083,1.38a1.4,1.4,0,0,1-.851,1.551Z" transform="translate(-296.926 -373.548)" fill="gray" />
                    <path id="Path_22410" data-name="Path 22410" d="M168.1,111.746h-5.932c-.9,0-1.322-.448-1.353-1.347a7.386,7.386,0,0,1,2.043-5.623,15.206,15.206,0,0,1,1.359-1.127.327.327,0,0,1,.467,0,5.683,5.683,0,0,0,6.909,0,.344.344,0,0,1,.468,0,7.278,7.278,0,0,1,3.387,5.907c.019.32.011.642.007.963a1.159,1.159,0,0,1-1.232,1.23c-2.041,0-4.083,0-6.124,0" transform="translate(-148.415 -95.57)" fill="gray" />
                    <path id="Path_22411" data-name="Path 22411" d="M214.4,0a3.465,3.465,0,1,1-3.462,3.449A3.47,3.47,0,0,1,214.4,0" transform="translate(-194.684 0)" fill="gray" />
                    <path id="Path_22412" data-name="Path 22412" d="M371.884,305.339a3.465,3.465,0,1,1,3.426,3.485,3.472,3.472,0,0,1-3.426-3.485" transform="translate(-343.226 -278.629)" fill="gray" />
                    <path id="Path_22413" data-name="Path 22413" d="M56.929,305.358a3.465,3.465,0,1,1-3.444-3.467,3.471,3.471,0,0,1,3.444,3.467" transform="translate(-46.145 -278.626)" fill="gray" />
                    <path id="Path_22414" data-name="Path 22414" d="M184.743,246.471c.159.175.266.282.361.4a1.151,1.151,0,0,1-.051,1.572,1.115,1.115,0,0,1-1.567.051q-1.241-1.178-2.421-2.42a1.087,1.087,0,0,1,.018-1.536q1.159-1.206,2.366-2.367a1.13,1.13,0,0,1,1.6.014,1.153,1.153,0,0,1,.039,1.6c-.09.106-.189.205-.346.376h.465c1.962,0,3.924,0,5.886,0a1.15,1.15,0,0,1,1.07,1.733,1.175,1.175,0,0,1-1.1.578q-2.9-.006-5.809,0Z" transform="translate(-166.826 -223.207)" fill="gray" />
                    <path id="Path_22415" data-name="Path 22415" d="M188.471,336.416c-2.183,0-4.274.005-6.365,0a1.157,1.157,0,1,1,.024-2.309q2.925,0,5.849,0h.488c-.147-.162-.248-.268-.343-.379a1.153,1.153,0,0,1,.046-1.6,1.12,1.12,0,0,1,1.568-.032q1.225,1.168,2.4,2.393a1.1,1.1,0,0,1-.014,1.566q-1.147,1.193-2.339,2.341a1.135,1.135,0,0,1-1.627-.013,1.158,1.158,0,0,1,.011-1.631c.083-.091.167-.182.306-.334" transform="translate(-166.945 -306.219)" fill="gray" />
                  </g>
                </svg>
              </div>
              <div className="card-body">
                <h5 className="card-title">Less reliance on intermediaries</h5>
              </div> </div>
          </div>
          <div className="binanceboxt card">
            <div className="binancecont">
              <div className="svgback">
                <svg id="Encrypted_digital_wallets" data-name="Encrypted digital wallets" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="39.455" height="36.631" viewBox="0 0 39.455 36.631">
                  <defs>
                    <clipPath id="clip-path">
                      <rect id="Rectangle_8920" data-name="Rectangle 8920" width="39.455" height="36.631" fill="gray" />
                    </clipPath>
                  </defs>
                  <g id="Group_28408" data-name="Group 28408" clipPath="url(#clip-path)">
                    <path id="Path_22416" data-name="Path 22416" d="M22.544,36.615V31.992c0-1.7,0-3.4,0-5.106a.56.56,0,0,1,.319-.586,3.469,3.469,0,0,0,1.558-4.317,3.516,3.516,0,0,0-6.793,1.439,3.464,3.464,0,0,0,1.8,2.9.615.615,0,0,1,.3.4c.015,3.257.009,6.514.006,9.772a.433.433,0,0,1-.035.1H15.5v-.449c0-2.113-.012-4.226.007-6.339a1.644,1.644,0,0,0-.868-1.557c-.819-.487-1.617-1.009-2.431-1.5a.468.468,0,0,1-.255-.493,3.513,3.513,0,1,0-3.308,3.3,5.278,5.278,0,0,0,1.5-.433.544.544,0,0,1,.588.011c.548.367,1.121.7,1.671,1.06a.765.765,0,0,1,.265.354,1.168,1.168,0,0,1,.01.393v5.658c-.172.008-.313.021-.455.021q-4.6,0-9.2,0A2.824,2.824,0,0,1,0,33.6Q0,19.341,0,5.079A4.907,4.907,0,0,1,5,.006Q18.475-.008,31.947,0a1.766,1.766,0,0,1,1.83,1.4,1.7,1.7,0,0,1-.921,1.91,2.679,2.679,0,0,1-1.063.2q-13.12.016-26.239.012a2.889,2.889,0,0,0-.781.085A1.741,1.741,0,0,0,3.53,5.464,1.778,1.778,0,0,0,5.165,7.04c.175.012.352.006.528.006H36.335a2.837,2.837,0,0,1,3.117,3.109q0,2.6,0,5.194a1.122,1.122,0,0,1-.03.149h-.449c-1.467,0-2.935-.005-4.4,0a6.332,6.332,0,0,0-1.343,12.533,7.027,7.027,0,0,0,1.4.138c1.438.017,2.876.006,4.314.006h.518v1.655c0,1.321.009,2.641,0,3.962a2.791,2.791,0,0,1-2.816,2.831c-4.593.011-9.187,0-13.78,0-.086,0-.171-.008-.308-.014" transform="translate(0 0)" fill="gray" />
                    <path id="Path_22417" data-name="Path 22417" d="M410.7,244.64c-1.752,0-3.484.066-5.208-.018a3.469,3.469,0,0,1-3.208-3.639,3.512,3.512,0,0,1,3.413-3.366c1.656-.027,3.313-.006,5-.006Z" transform="translate(-371.281 -219.293)" fill="gray" />
                  </g>
                </svg>
              </div>
              <div className="card-body">
                <h5 className="card-title">Encrypted digital wallets</h5>
              </div> </div>
          </div>
          <div className="binanceboxt card">
            <div className="binancecont">
              <div className="svgback">
                <svg id="Two-factor_authentication" data-name="Two-factor authentication" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="39.455" height="39.449" viewBox="0 0 39.455 39.449">
                  <defs>
                    <clipPath id="clip-path">
                      <rect id="Rectangle_8916" data-name="Rectangle 8916" width="39.455" height="39.449" fill="gray" />
                    </clipPath>
                  </defs>
                  <g id="Group_28400" data-name="Group 28400" clipPath="url(#clip-path)">
                    <path id="Path_22389" data-name="Path 22389" d="M0,148.027a9,9,0,0,0,4.579-3.885,8.764,8.764,0,0,0,1.2-4.288c.025-1.18-.029-2.367.078-3.54a10.383,10.383,0,0,1,20.619-.327,36.561,36.561,0,0,1,.113,3.769,8.716,8.716,0,0,0,3.708,7.136,12.917,12.917,0,0,0,1.776.99c.206.106.316.206.3.456-.041.66-.056,1.322-.086,2.076-.526-.2-.965-.336-1.372-.536a11.075,11.075,0,0,1-6.636-10.15c-.043-1.115.021-2.239-.086-3.346a8.071,8.071,0,0,0-13.28-5.117,1.157,1.157,0,0,0-.24,1.7,1.169,1.169,0,0,0,1.743.057,5.76,5.76,0,0,1,8.2.674,5.671,5.671,0,0,1,1.349,3.643c.022,1.206-.032,2.419.079,3.617a13.525,13.525,0,0,0,9.164,11.524c.071.024.14.052.25.094a6.87,6.87,0,0,1-1.38,1.844c-.06.057-.238.036-.339,0a15.9,15.9,0,0,1-9.918-12.441,1.158,1.158,0,1,0-2.272.361,18,18,0,0,0,9.822,13.552c.075.04.146.085.219.127l.013.122-2.169.525H23.966c-.835-.7-1.7-1.365-2.5-2.1a19.867,19.867,0,0,1-4.174-5.525,1.234,1.234,0,0,0-.979-.769,1.083,1.083,0,0,0-1.068.473,1.173,1.173,0,0,0-.04,1.3,23.22,23.22,0,0,0,4.814,6.337,3.559,3.559,0,0,1,.246.282H17.031c-.906-1.191-1.816-2.378-2.714-3.575a1.186,1.186,0,0,0-1.621-.361,1.137,1.137,0,0,0-.293,1.652c.524.773,1.08,1.523,1.623,2.283H6.936l-2.167-.523.013-.124c.085-.048.172-.093.256-.144.863-.529,1.773-1,2.58-1.6a17.611,17.611,0,0,0,7.246-12.348c.18-1.458.121-2.945.161-4.419a1.182,1.182,0,0,1,1.129-1.259,1.168,1.168,0,0,1,1.185,1.163,1.157,1.157,0,1,0,2.308-.129,3.487,3.487,0,0,0-3.183-3.337,3.4,3.4,0,0,0-3.656,2.783,21.161,21.161,0,0,0-.089,2.918,15.617,15.617,0,0,1-1.71,7.192,15.711,15.711,0,0,1-8.408,7.59.385.385,0,0,1-.333-.025A6.753,6.753,0,0,1,.931,152.6c.041-.042.055-.069.076-.077.1-.037.194-.067.289-.1a13.383,13.383,0,0,0,8.747-9.638,20.918,20.918,0,0,0,.358-5.322,1.156,1.156,0,1,0-2.308.009c-.005.744,0,1.489,0,2.234A11.234,11.234,0,0,1,.584,150.229c-.139.049-.282.085-.432.129L0,149.722v-1.7" transform="translate(0 -117.207)" fill="gray" />
                    <path id="Path_22390" data-name="Path 22390" d="M0,58.876c.1-.436.172-.878.292-1.308a7.658,7.658,0,0,1,7.345-5.621c4.944-.017,9.888,0,14.832,0,.076,0,.153.006.281.012a10.121,10.121,0,0,0-.528,4.948c-.177-.076-.3-.122-.419-.18A12.731,12.731,0,0,0,3.716,65.589a27.144,27.144,0,0,0-.261,4.643,8.771,8.771,0,0,1-.288,2.084A6.35,6.35,0,0,1,0,76.212V58.876" transform="translate(0 -47.935)" fill="gray" />
                    <path id="Path_22391" data-name="Path 22391" d="M324.541,0a7.512,7.512,0,1,1-7.531,7.464A7.524,7.524,0,0,1,324.541,0m-.991,8.2c-.288-.3-.543-.583-.823-.843a1.149,1.149,0,0,0-1.62,1.629q.812.848,1.661,1.661a1.1,1.1,0,0,0,1.539.045c1.151-1.117,2.3-2.243,3.4-3.405a1.125,1.125,0,0,0-.572-1.842,1.142,1.142,0,0,0-1.186.358c-.787.788-1.576,1.574-2.4,2.4" transform="translate(-292.581 0)" fill="gray" />
                    <path id="Path_22392" data-name="Path 22392" d="M372.808,228.254a6.486,6.486,0,0,1-3.277-4.357,8.62,8.62,0,0,1-.159-1.679,23.679,23.679,0,0,0-.239-4.448c-.071-.337-.149-.672-.239-1.077a10.438,10.438,0,0,0,3.914.632Z" transform="translate(-340.467 -199.994)" fill="gray" />
                  </g>
                </svg>
              </div>
              <div className="card-body">
                <h5 className="card-title">Two-factor authentication</h5>
              </div> </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default SecurityofOur