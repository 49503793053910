import React from 'react'




const HowOur = () => {



  return (
    <section className="how-our mb-0">
      <div className="container">
        <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">

          <h2 className="heading-h2"><span className="heading-h3 bluecolor">How our</span>
            UniSwap Clone Script work?</h2>
        </div>
        <svg xmlns="http://www.w3.org/2000/svg" width="1128.115" height="1588.505" viewBox="0 0 1128.115 1588.505">
          <g id="Flow" transform="translate(-448 -260.494)">
            <g id="Ellipse_196" data-name="Ellipse 196" transform="translate(522.612 299.494)" fill="none" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5}>
              <circle cx={108} cy={108} r={108} stroke="none" />
              <circle cx={108} cy={108} r={107} fill="none" />
            </g>
            <g id="Ellipse_196-2" data-name="Ellipse 196" transform="translate(1302.612 260.494)" fill="none" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5}>
              <circle cx={108} cy={108} r={108} stroke="none" />
              <circle cx={108} cy={108} r={107} fill="none" />
            </g>
            <g id="Admin" transform="translate(1254.336 295.381)">
              <g id="Group_3" data-name="Group 3" transform="translate(8.322 29.701)">
                <path id="Path_24" data-name="Path 24" d="M607.692,343.877H578.544c-.859,0-1.554.4-1.553.893v26.413h32.258V344.77c0-.493-.7-.893-1.557-.893Zm-14.476,15.008c-1.9-.005-3.433-.886-3.442-1.974v-3.046c0-1.09,1.541-1.974,3.442-1.974s3.442.884,3.442,1.974v3.046C596.65,358,595.114,358.879,593.217,358.885Z" transform="translate(-527.325 -251.723)" fill="#ccc" />
                <path id="Path_28" data-name="Path 28" d="M615.932,430.558H543.042a1.341,1.341,0,0,1-1.312-1.617l1.131-5.372a1.347,1.347,0,0,1,1.312-1.065H614.8a1.347,1.347,0,0,1,1.312,1.065l1.131,5.372a1.341,1.341,0,0,1-1.312,1.617Z" transform="translate(-512.202 -305.714)" fill="#ccc" />
                <rect id="Rectangle_1" data-name="Rectangle 1" width="2.245" height="1.347" rx="0.488" transform="translate(32.489 117.757)" fill="#e6e6e6" />
                <rect id="Rectangle_2" data-name="Rectangle 2" width="2.245" height="1.347" rx="0.488" transform="translate(36.979 117.757)" fill="#e6e6e6" />
                <rect id="Rectangle_3" data-name="Rectangle 3" width="2.245" height="1.347" rx="0.488" transform="translate(41.468 117.757)" fill="#e6e6e6" />
                <rect id="Rectangle_4" data-name="Rectangle 4" width="2.245" height="1.347" rx="0.488" transform="translate(45.958 117.757)" fill="#e6e6e6" />
                <rect id="Rectangle_5" data-name="Rectangle 5" width="2.245" height="1.347" rx="0.488" transform="translate(50.448 117.757)" fill="#e6e6e6" />
                <rect id="Rectangle_6" data-name="Rectangle 6" width="2.245" height="1.347" rx="0.488" transform="translate(54.938 117.757)" fill="#e6e6e6" />
                <rect id="Rectangle_7" data-name="Rectangle 7" width="2.245" height="1.347" rx="0.488" transform="translate(59.427 117.757)" fill="#e6e6e6" />
                <rect id="Rectangle_8" data-name="Rectangle 8" width="2.245" height="1.347" rx="0.488" transform="translate(63.917 117.757)" fill="#e6e6e6" />
                <rect id="Rectangle_9" data-name="Rectangle 9" width="2.245" height="1.347" rx="0.488" transform="translate(68.407 117.757)" fill="#e6e6e6" />
                <rect id="Rectangle_10" data-name="Rectangle 10" width="2.245" height="1.347" rx="0.488" transform="translate(72.897 117.757)" fill="#e6e6e6" />
                <rect id="Rectangle_11" data-name="Rectangle 11" width="2.245" height="1.347" rx="0.488" transform="translate(77.387 117.757)" fill="#e6e6e6" />
                <rect id="Rectangle_12" data-name="Rectangle 12" width="2.245" height="1.347" rx="0.488" transform="translate(81.877 117.757)" fill="#e6e6e6" />
                <rect id="Rectangle_13" data-name="Rectangle 13" width="2.245" height="1.347" rx="0.488" transform="translate(86.367 117.757)" fill="#e6e6e6" />
                <rect id="Rectangle_14" data-name="Rectangle 14" width="2.245" height="1.347" rx="0.488" transform="translate(90.857 117.757)" fill="#e6e6e6" />
                <rect id="Rectangle_15" data-name="Rectangle 15" width="2.245" height="1.347" rx="0.488" transform="translate(95.346 117.757)" fill="#e6e6e6" />
                <rect id="Rectangle_16" data-name="Rectangle 16" width="2.245" height="1.347" rx="0.488" transform="translate(99.836 117.757)" fill="#e6e6e6" />
                <rect id="Rectangle_17" data-name="Rectangle 17" width="2.245" height="1.347" rx="0.488" transform="translate(32.436 120.003)" fill="#e6e6e6" />
                <rect id="Rectangle_18" data-name="Rectangle 18" width="2.245" height="1.347" rx="0.488" transform="translate(36.925 120.003)" fill="#e6e6e6" />
                <rect id="Rectangle_19" data-name="Rectangle 19" width="2.245" height="1.347" rx="0.488" transform="translate(41.415 120.003)" fill="#e6e6e6" />
                <rect id="Rectangle_20" data-name="Rectangle 20" width="2.245" height="1.347" rx="0.488" transform="translate(45.905 120.003)" fill="#e6e6e6" />
                <rect id="Rectangle_21" data-name="Rectangle 21" width="2.245" height="1.347" rx="0.488" transform="translate(50.395 120.003)" fill="#e6e6e6" />
                <rect id="Rectangle_22" data-name="Rectangle 22" width="2.245" height="1.347" rx="0.488" transform="translate(54.885 120.003)" fill="#e6e6e6" />
                <rect id="Rectangle_23" data-name="Rectangle 23" width="2.245" height="1.347" rx="0.488" transform="translate(59.375 120.003)" fill="#e6e6e6" />
                <rect id="Rectangle_24" data-name="Rectangle 24" width="2.245" height="1.347" rx="0.488" transform="translate(63.864 120.003)" fill="#e6e6e6" />
                <rect id="Rectangle_25" data-name="Rectangle 25" width="2.245" height="1.347" rx="0.488" transform="translate(68.354 120.003)" fill="#e6e6e6" />
                <rect id="Rectangle_26" data-name="Rectangle 26" width="2.245" height="1.347" rx="0.488" transform="translate(72.844 120.003)" fill="#e6e6e6" />
                <rect id="Rectangle_27" data-name="Rectangle 27" width="2.245" height="1.347" rx="0.488" transform="translate(77.334 120.003)" fill="#e6e6e6" />
                <rect id="Rectangle_28" data-name="Rectangle 28" width="2.245" height="1.347" rx="0.488" transform="translate(81.823 120.003)" fill="#e6e6e6" />
                <rect id="Rectangle_29" data-name="Rectangle 29" width="2.245" height="1.347" rx="0.488" transform="translate(86.313 120.003)" fill="#e6e6e6" />
                <rect id="Rectangle_30" data-name="Rectangle 30" width="2.245" height="1.347" rx="0.488" transform="translate(90.803 120.003)" fill="#e6e6e6" />
                <rect id="Rectangle_31" data-name="Rectangle 31" width="2.245" height="1.347" rx="0.488" transform="translate(95.293 120.003)" fill="#e6e6e6" />
                <rect id="Rectangle_32" data-name="Rectangle 32" width="2.245" height="1.347" rx="0.488" transform="translate(99.783 120.003)" fill="#e6e6e6" />
                <rect id="Rectangle_33" data-name="Rectangle 33" width="17.959" height="1.347" rx="0.488" transform="translate(53.986 122.697)" fill="#e6e6e6" />
                <g id="Group_4" data-name="Group 4" transform="translate(0 11)">
                  <path id="Path_26" data-name="Path 26" d="M618.714,218.133h-124.6a4.045,4.045,0,0,0-4.038,4.038v84.077a4.042,4.042,0,0,0,4.038,4.038h124.6a4.042,4.042,0,0,0,4.038-4.038V222.171A4.045,4.045,0,0,0,618.714,218.133Z" transform="translate(-490.081 -218.133)" fill="#e6e6e6" />
                  <path id="Path_27" data-name="Path 27" d="M619.863,223.236H497.348a2.164,2.164,0,0,0-2.16,2.164v81.994a2.163,2.163,0,0,0,2.16,2.16H619.863a2.163,2.163,0,0,0,2.16-2.16V225.4a2.164,2.164,0,0,0-2.159-2.164Z" transform="translate(-492.27 -220.32)" fill="#fff" />
                  <path id="Path_29" data-name="Path 29" d="M616.271,242.321H501.635a.456.456,0,1,1,0-.912H616.271a.456.456,0,1,1,0,.912Z" transform="translate(-494.837 -228.108)" fill="#cacaca" />
                  <ellipse id="Ellipse_20" data-name="Ellipse 20" cx="2.672" cy="2.731" rx="2.672" ry="2.731" transform="translate(11.93 5.512)" fill="#ccc" />
                  <ellipse id="Ellipse_21" data-name="Ellipse 21" cx="2.672" cy="2.731" rx="2.672" ry="2.731" transform="translate(21.159 5.512)" fill="#ccc" />
                  <ellipse id="Ellipse_22" data-name="Ellipse 22" cx="2.672" cy="2.731" rx="2.672" ry="2.731" transform="translate(30.389 5.512)" fill="#ccc" />
                  <path id="Path_30" data-name="Path 30" d="M687.566,228.962H681.01a.5.5,0,1,0,0,.993h6.556a.5.5,0,1,0,0-.993Z" transform="translate(-571.688 -222.774)" fill="#ccc" />
                  <path id="Path_31" data-name="Path 31" d="M687.566,232.223H681.01a.5.5,0,1,0,0,.993h6.556a.5.5,0,1,0,0-.993Z" transform="translate(-571.688 -224.171)" fill="#ccc" />
                  <path id="Path_32" data-name="Path 32" d="M687.566,235.479H681.01a.5.5,0,1,0,0,.993h6.556a.5.5,0,1,0,0-.993Z" transform="translate(-571.688 -225.566)" fill="#ccc" />
                  <path id="Path_66" data-name="Path 66" d="M606.036,308.394H556.917a7.737,7.737,0,0,1-7.728-7.728V268.119a7.737,7.737,0,0,1,7.728-7.728h49.119a7.737,7.737,0,0,1,7.728,7.728v32.547a7.737,7.737,0,0,1-7.728,7.728Zm-49.119-46.86a6.593,6.593,0,0,0-6.585,6.585v32.546a6.593,6.593,0,0,0,6.585,6.585h49.119a6.593,6.593,0,0,0,6.585-6.585V268.119a6.593,6.593,0,0,0-6.585-6.585Z" transform="translate(-515.411 -236.242)" fill="#e6e6e6" />
                  <rect id="Rectangle_34" data-name="Rectangle 34" width="13.139" height="1.143" transform="translate(42.921 34.435)" fill="#e6e6e6" />
                  <rect id="Rectangle_35" data-name="Rectangle 35" width="29.145" height="1.143" transform="translate(42.921 39.007)" fill="#e6e6e6" />
                  <rect id="Rectangle_36" data-name="Rectangle 36" width="29.145" height="1.143" transform="translate(42.921 43.007)" fill="#e6e6e6" />
                  <rect id="Rectangle_37" data-name="Rectangle 37" width="37.145" height="1.143" transform="translate(42.921 47.007)" fill="#e6e6e6" />
                  <rect id="Rectangle_38" data-name="Rectangle 38" width="19.43" height="1.143" transform="translate(42.921 51.007)" fill="#e6e6e6" />
                  <rect id="Rectangle_39" data-name="Rectangle 39" width="35.431" height="1.143" transform="translate(42.921 55.008)" fill="#e6e6e6" />
                  <rect id="Rectangle_40" data-name="Rectangle 40" width="6.286" height="1.143" transform="translate(42.921 59.008)" fill="#e6e6e6" />
                </g>
              </g>
              <path id="Path_23" data-name="Path 23" d="M284.9,539.016a.761.761,0,0,0,.4.61,1.792,1.792,0,0,0,.948.249h5.408a1.8,1.8,0,0,0,.953-.251.743.743,0,0,0,.393-.608v-104.4c0-2.3,1.211-4.169,2.7-4.174H632.346c1.493,0,2.7,1.872,2.7,4.174V538.376c0,.442.528.811,1.217.854,1.09.069,2.179.129,3.262.185.027,0,.054,0,.081,0,.592.034,1.184.06,1.776.09h.007c.309-.356.605-.721.908-1.086a.649.649,0,0,0,.148-.4V429.2a18.03,18.03,0,0,0-.108-1.906,16.131,16.131,0,0,0-.5-2.585c-1.137-3.989-3.706-6.69-6.625-6.7H292.841a4.718,4.718,0,0,0-1.238.167,5.435,5.435,0,0,0-1.527.683,7.127,7.127,0,0,0-1.5,1.31,12.818,12.818,0,0,0-2.832,6.84c0,.026-.007.052-.007.077a14.726,14.726,0,0,0-.134,2.113l-.094,9.2-.061,5.488-.182,17.218-.067,6.114Z" transform="translate(-338.683 -263.466)" fill="#e6e6e6" />
              <path id="Path_44" data-name="Path 44" d="M444.546,445.571h34.288a4.572,4.572,0,0,1,0,9.143H466.261v35.431h-9.143V454.714H444.546a4.572,4.572,0,1,1,0-9.143Z" transform="translate(-268.255 -315.599)" fill="#3f3d56" />
              <path id="Path_45" data-name="Path 45" d="M267.144,443.488h91.71a37.034,37.034,0,0,0,36.526-31.794l3.246-11.941a268.061,268.061,0,0,1,30.391-70.234c6.142-9.974,9.3-19.629,2.123-28.925l-.18-.234c-5.759-7.5-34.422-9.118-43.766-9.434q-.554-.019-1.112-.019H329.039q-.558,0-1.111.019a27.914,27.914,0,0,0-24.136,15.384,28.762,28.762,0,0,0,1.632,29.447c20.037,30.1,26.172,48.973,19.891,61.195-6.075,11.822-24.7,17.867-58.609,19.021-9.113.31-13.295,7.406-13.324,13.867-.028,6.294,3.992,13.244,12.888,13.628Q266.7,443.488,267.144,443.488Z" transform="translate(-113.484 -249.32)" fill="#3f3d56" />
              <path id="Path_46" data-name="Path 46" d="M445.214,635.7H410.927a4.572,4.572,0,1,1,0-9.143H423.5V549.408h9.143v77.147h12.572a4.572,4.572,0,1,1,0,9.143Z" transform="translate(-215.425 -360.097)" fill="#3f3d56" />
              <path id="Path_47" data-name="Path 47" d="M291.506,432.234l-5.907,3.208-15.636-21.619,8.718-4.736Z" transform="translate(-92.75 -187.735)" fill="#efa7b1" />
              <path id="Path_48" data-name="Path 48" d="M477.673,632.5,466.95,635.86l1.707,4.492L461.3,651.024a3,3,0,0,0,3.538,4.509l12.483-4.743.463-5.261,2.528,4.124,4.707-1.788Z" transform="translate(-281.76 -395.706)" fill="#2f2e41" />
              <path id="Path_49" data-name="Path 49" d="M430.635,260.152a6.637,6.637,0,0,0,6.778,7.592L460.186,316.2l7.143-9.96-23.444-45.82a6.673,6.673,0,0,0-13.251-.268Z" transform="translate(-246.802 -233.674)" fill="#efa7b1" />
              <path id="Path_50" data-name="Path 50" d="M422.882,340.58s.61,8.465-8.963,10.126-35.167-14.524-39.37-17.009-12.036-33.751-12.036-33.751l6.812-11.522c8.41,10.639,15.015,20.449,15.416,27.413Z" transform="translate(-163.474 -248.255)" fill="#ff6464" />
              <path id="Path_51" data-name="Path 51" d="M418.525,426.13l-2.068,8.884s-.02,8.192-1.471,9.7-33.119-1.515-33.119-1.515,3.359-13.2,3.314-23.189Z" transform="translate(-170.17 -304.646)" fill="#efa7b1" />
              <path id="Path_52" data-name="Path 52" d="M407.748,553.66l11.429-6.286c.217-14.872-8.728-35-19.338-56.079,23.729,4.081,46.115,5.388,65.477.452a12.516,12.516,0,0,0,8.951-15.12q-.113-.47-.261-.93c-2.317-7.07,2.879-11.5-1.9-19.279-12.57-4.293-24.511-5.505-35.431-1.714l-6.842,15.127-33.912-4.388a12.5,12.5,0,0,0-13.911,11.026q-.068.552-.089,1.109C388.777,509.724,396.466,538.649,407.748,553.66Z" transform="translate(-226.314 -318.539)" fill="#2f2e41" />
              <path id="Path_53" data-name="Path 53" d="M403.434,331.713l-13.064-.771a2.652,2.652,0,0,0-2.608,1.636l-3.692,8.95L368.726,354.21s-6.075,7.731-6.089,17.715-2.155,33.093-2.155,33.093,8.866-12.48,14.339-9.161c.931.565,1.259,1.72,2.234,2.3.486.291,1.61.008,2.1.3,7.541,4.477,15.405,8.85,16.14,8.083,1.443-1.506,3.193-9.2,3.193-9.2l11.959-43.938a16.273,16.273,0,0,0-2.314-13.693,4.918,4.918,0,0,0-2.524-2.2C403.782,337.128,404.754,333.034,403.434,331.713Z" transform="translate(-150.475 -266.474)" fill="#ff6464" />
              <path id="Path_54" data-name="Path 54" d="M348.645,461.61l-5.515-3.834,12.493-23.569,8.139,5.659Z" transform="translate(-206.817 -198.499)" fill="#efa7b1" />
              <path id="Path_55" data-name="Path 55" d="M388.166,568.965l8.643,7.725c13.742-15.313,24.131-36.788,32.935-61.177,23.068-6.756,40.464-17.652,55.65-30.588,5.152-4.359,9.014-9.96,4.62-15.27q-.308-.372-.643-.72c-5.188-5.332-18.344-4.316-26.05-9.2l-17.213,18.4,1.083,4.933-32.332,10.952a12.488,12.488,0,0,0-7.621,16.011q.182.526.408,1.035Z" transform="translate(-247.172 -321.669)" fill="#2f2e41" />
              <path id="Path_56" data-name="Path 56" d="M590.2,670.618l-8.828-6.952-2.743,3.946-12.96-.039a3,3,0,0,0-1.723,5.467l10.965,7.622,4.6-2.589-1.975,4.416,4.134,2.874Z" transform="translate(-445.153 -409.061)" fill="#2f2e41" />
              <path id="Path_57" data-name="Path 57" d="M359.945,484.6a6.637,6.637,0,0,0,2.966-9.736l29.576-44.632-12.221-.929-26.974,43.835a6.673,6.673,0,0,0,6.654,11.462Z" transform="translate(-126.278 -308.626)" fill="#efa7b1" />
              <path id="Path_58" data-name="Path 58" d="M360.914,351.988s6.917-4.919,13.309,2.4,5.858,37.595,5.918,42.477c.019,1.529-2.189,4.828-5.231,8.639-1.366,1.711-2.9,3.525-4.475,5.328-.912,1.043-.126,3.215-1.042,4.229s-3.523.86-4.389,1.8c-4.111,4.443-7.451,7.826-7.451,7.826l-13.384.165c-.151-9.949,6.662-18.83,15.416-27.413Z" transform="translate(-110.242 -274.802)" fill="#ff6464" />
              <path id="Path_59" data-name="Path 59" d="M347.409,269.423a9.837,9.837,0,0,0,4.146,2.083c1.546.227,3.356-.521,3.784-2.024.523-1.838-1.136-3.511-2.628-4.705l-4.729-3.787a11.433,11.433,0,0,0-2.9-1.875,3.864,3.864,0,0,0-3.346.112,3.593,3.593,0,0,0-1.579,3.128,6.678,6.678,0,0,0,1.171,3.426,12.051,12.051,0,0,0,7.824,5.318" transform="translate(-83.246 -235.555)" fill="#2f2e41" />
              <path id="Path_60" data-name="Path 60" d="M353.546,449.571h34.288a4.572,4.572,0,1,1,0,9.143H375.261v35.431h-9.143V458.714H353.546a4.572,4.572,0,1,1,0-9.143Z" transform="translate(-125.253 -317.313)" fill="#3f3d56" />
              <path id="Path_61" data-name="Path 61" d="M292.98,256.16c-.138-3.428,3.515-6.142,6.937-5.927s6.365,2.622,8.306,5.45,3.106,6.1,4.635,9.168,3.564,6.073,6.6,7.663,7.265,1.3,9.364-1.406a7.548,7.548,0,0,1,.775,6.781,10.336,10.336,0,0,1-4.536,5.264,11.883,11.883,0,0,1-2.626,1.155,10.693,10.693,0,0,0-1.581-2.843,8.83,8.83,0,0,1,.34,3.147,10.507,10.507,0,0,1-4.28-.067,9.112,9.112,0,0,1-6.321-5.155c-1.284-3.039-.63-6.5-.9-9.791s-2.253-7.041-5.552-7.095c-2.512-.041-2.18.166-3.772,2.11" transform="translate(-30.918 -231.884)" fill="#2f2e41" />
              <path id="Path_62" data-name="Path 62" d="M343.8,280.991a14.858,14.858,0,0,0,14.858,14.858c8.206,0,16-9.509,16-17.715s-7.8-12-16-12A14.858,14.858,0,0,0,343.8,280.991Z" transform="translate(-104.554 -238.703)" fill="#2f2e41" />
              <ellipse id="Ellipse_23" data-name="Ellipse 23" cx="14.175" cy="14.175" rx="14.175" ry="14.175" transform="matrix(-0.98, -0.2, 0.2, -0.98, 260.672, 62.934)" fill="#efa7b1" />
              <path id="Path_63" data-name="Path 63" d="M345.8,284.848c4.2,1.146,8.433,2.347,10.069,0a12.9,12.9,0,0,1,1.777-4.377,17.721,17.721,0,0,0,1.046,4.377c5.3,1.152,10.256,1.428,14.538,0a8.117,8.117,0,0,1,1,3.463,4.886,4.886,0,0,1,.663-.349,1.1,1.1,0,0,1,.9-.08.78.78,0,0,1,.371.577,2.155,2.155,0,0,1-.034.714,5.43,5.43,0,0,1-.714,2.32,2.116,2.116,0,0,1-1.434.92c-.594,3.343-5.326,7.863-5.326,7.863,14.287.571,14.287-9.121,14.287-18.287l-1.714-3.429h-5.715v-7.429h-16A13.719,13.719,0,0,0,345.8,284.848Z" transform="translate(-113.983 -240.846)" fill="#2f2e41" />
            </g>
            <g id="User" transform="translate(216.304 299.494)">
              <path id="Path_6" data-name="Path 6" d="M812.632,211.954c-1.135-8.7-21.2-8.144-23.094.04-1.005-.629-3.561-.193-4.515.529a9.035,9.035,0,0,0-2.163,2.816,12.179,12.179,0,0,0-1.844,4.557,4.146,4.146,0,0,0,1.941,4.225c1.179.581,3.772.189,5.068-.072.9-.18,1.663-.337,2.307-.492a4.43,4.43,0,0,0-.422-3,3.6,3.6,0,0,1,2.468,1.826c.1.2,3.778,27.867,3.778,27.867a15.969,15.969,0,0,0,3.029.076A34.745,34.745,0,0,0,799.522,240a30.092,30.092,0,0,1,2.511,6.2,36.808,36.808,0,0,1,.852,3.657c8.749-1.636,20.575-7.271,22.732-16.838l-.036-.093a1.359,1.359,0,0,1,2.237-1.453c.264.271.5.5.7.683C827.75,226.044,823.115,220.545,812.632,211.954Z" transform="translate(-313.495 -203.516)" fill="#2f2e41" />
              <path id="Path_7" data-name="Path 7" d="M393.275,447.246l-4.9-2.564,7.778-20.561,7.239,3.785Z" transform="translate(-21.877 -225.767)" fill="#ec9da8" />
              <path id="Path_8" data-name="Path 8" d="M532.885,667.163a2.478,2.478,0,0,0,1.046,3.34l9.753,5.1,3.495-2.6-1.159,3.816,3.678,1.926,5.448-12.937-1.242-.744-5.059-3.047-1.634-.98-1.835,3.51-10.6,1.307a2.474,2.474,0,0,0-1.889,1.309Z" transform="translate(-181.278 -445.931)" fill="#2f2e41" />
              <path id="Path_9" data-name="Path 9" d="M384.052,288.6l-3.568-4.232,14.988-16.081,5.265,6.245Z" transform="translate(-17.68 -142.815)" fill="#ec9da8" />
              <path id="Path_10" data-name="Path 10" d="M524.817,495.633a2.478,2.478,0,0,0-.3,3.487l7.094,8.413,4.218-1.08-2.519,3.092,2.674,3.176,9.944-9.908-.867-1.159-3.527-4.737-1.141-1.526-3.028,2.553-10.306-2.807A2.473,2.473,0,0,0,524.817,495.633Z" transform="translate(-176.665 -357.574)" fill="#2f2e41" />
              <path id="Path_11" data-name="Path 11" d="M730.965,408.293s15.146,32.419-47.577,23.684c0,0-33.418-37.035-32.453-41.491,0,0-14.223-14.453-13.527-14.716s1.125-.835-.025-.783-1.15-1.66-1.15-1.66-25.7,27.875-47.348,41.708l-7.483-8.418s2.918-9.129,4.321-9.129,1.887.337,2.112-1.468-.891-3.356.6-3.516S620.9,356.155,620.9,356.155s3.341-11.92,13.548-9.613S697.69,391.97,697.69,391.97Z" transform="translate(-207.254 -278.363)" fill="#2f2e41" />
              <path id="Path_12" data-name="Path 12" d="M699.694,503.737l-82.865-.688-36.012,57.057-10.177-5.387,2.256-.952s-.683-7.93.154-9.938,3.326-1.4,3.326-1.4l21-48.419a20.314,20.314,0,0,1,20.9-13.76c17.373-2.534,45.574-.509,74.872,1.977Z" transform="translate(-201.526 -349.073)" fill="#2f2e41" />
              <path id="Path_13" data-name="Path 13" d="M736.274,324.082a6.126,6.126,0,0,1,5.15,6.818,5.881,5.881,0,0,1-.215.947l17.027,12.915-3.514,10.946L733.31,335.494a6.109,6.109,0,0,1-3.776-6.431,5.846,5.846,0,0,1,6.581-5Z" transform="translate(-286.082 -266.523)" fill="#ec9da8" />
              <path id="Path_14" data-name="Path 14" d="M822.663,303.527l-6.122,16.949L792.6,354.308l-.552-.234c-2.058-.889-9.531-4.153-16.561-7.862-6.6-3.484-12.815-7.361-13.778-10.027q-.154-.421-.281-.814v0a17.538,17.538,0,0,1-.837-4.19c-.08-1.1-.009-1.688-.159-1.759-.117-.051-.36.2-.9.772-.037.037-.07.075-.108.108-1.946,1.95-4.335,1.7-3.751.772.6-.949.285-.931-.594-1.922-.655-.739-2.783-3.629-3.83-5.056-.36-.491-.589-.809-.589-.809l.36-.566,1-1.562,4.738-7.427.262-.407s9.737,2.25,11.033,4.387-1.96,2.067,1.3,2.137,6.585-4.363,4.2.047-1.754,5.944,1.193,5.247c1.2-.285,3.091.617,4.915,1.787v0a38.594,38.594,0,0,1,3.3,2.4c1.127.907,1.889,1.595,1.889,1.595l18.492-37.518,2.048-4.153a10.6,10.6,0,0,1,6.2-5.378l.641,1.118a126.817,126.817,0,0,1,10.429,18.534Z" transform="translate(-297.355 -245.162)" fill="#6c63ff" />
              <path id="Path_15" data-name="Path 15" d="M851.443,347.2c5.668,1.319-.362,42.58-7.118,42.709-49.107.935-34.155-26.616-36.788-29.254s-5.009-.748-2.633-4.859c1.73-2.993,4.3-2.549,4.335-4.228a6.679,6.679,0,0,0-1.174-3.049c-3.162-5.537-3.162-7.188-3.162-7.188a20.318,20.318,0,0,1-1.66-5.238,24.874,24.874,0,0,1-.187-8.175,26.578,26.578,0,0,1,4.363-11.107v0c.238-.355.486-.716.748-1.071.594-.809,3.886-.655,3.886-.655l7.015-29.464-4.059-11.748,19.226-9.008,5.112,8.9.641,1.118A126.815,126.815,0,0,1,850.419,293.4c2.839,6.318,5.172,13.334,5.257,19.437.224,15.915-2.521,20.648-2.521,20.648s6.665,4.981,3.33,8.025S845.775,345.876,851.443,347.2Z" transform="translate(-325.111 -235.037)" fill="#6c63ff" />
              <path id="Path_16" data-name="Path 16" d="M715.675,315.2l-3.137,2.383a2.948,2.948,0,0,0-.557,4.124l7.555,9.931L724,337.495a2.943,2.943,0,0,0,4.118.557l3.141-2.386a2.953,2.953,0,0,0,.554-4.121l-.463-.612-9.152-12.025-2.4-3.152A2.937,2.937,0,0,0,715.675,315.2Z" transform="translate(-276.444 -261.515)" fill="#3f3d56" />
              <path id="Path_17" data-name="Path 17" d="M718.926,314.875l-3.379,2.357a2.519,2.519,0,0,0-.551,3.728l4.423,5.33,2.357,2.839,1.573,1.894,3.517,4.238a3.077,3.077,0,0,0,4.065.506L733.463,334l.843-.588A2.561,2.561,0,0,0,735.4,330.8a2.526,2.526,0,0,0-.541-1.118l-10.393-12.524-1.473-1.773A3.073,3.073,0,0,0,718.926,314.875Z" transform="translate(-278.055 -261.372)" fill="#6c63ff" />
              <path id="Path_18" data-name="Path 18" d="M720.962,343.555a6.126,6.126,0,0,1,5.15,6.817,5.876,5.876,0,0,1-.215.947l17.027,12.915L739.41,375.18,718,354.966a6.109,6.109,0,0,1-3.776-6.431,5.846,5.846,0,0,1,6.581-5Z" transform="translate(-277.931 -276.888)" fill="#ec9da8" />
              <path id="Path_19" data-name="Path 19" d="M801.679,302.445h0a10.578,10.578,0,0,1,7.839,13.958l-8.287,22.951-23.945,33.835s-28.716-12.13-30.89-18.125-.211-8.064-2.174-6-4.453,1.823-3.857.877.282-.926-.6-1.918-4.419-5.865-4.419-5.865l6.355-9.962s9.737,2.248,11.035,4.387-1.963,2.069,1.3,2.139,6.587-4.365,4.205.044-1.754,5.946,1.193,5.247,10.1,5.787,10.1,5.787l20.545-41.67a10.578,10.578,0,0,1,11.6-5.688Z" transform="translate(-289.204 -254.933)" fill="#6c63ff" />
              <circle id="Ellipse_9" data-name="Ellipse 9" cx="15.243" cy="15.243" r="15.243" transform="translate(467.889 22.918) rotate(-45)" fill="#ec9da8" />
              <path id="Path_20" data-name="Path 20" d="M819.346,207.412c-1.135-8.7-21.2-8.144-23.094.04-1.005-.629-3.561-.193-4.515.529a9.036,9.036,0,0,0-2.163,2.816c-.817,1.432-6.532,6.277-7.389,4.864-1.927-3.179-5.707,3.768-2.806,8.418.7,1.115,14.138-.675,15.433-.935.9-.18-.644.154,0,0,.208-1.009,2.308-6.222,1.812-7.128a3.6,3.6,0,0,1,2.468,1.826c.1.2,3.778,27.867,3.778,27.867a15.966,15.966,0,0,0,3.029.076,34.747,34.747,0,0,0,.338-10.325,30.089,30.089,0,0,1,2.511,6.2,36.776,36.776,0,0,1,.852,3.657c8.749-1.636,20.575-7.271,22.733-16.838C820.535,224.547,829.829,216,819.346,207.412Z" transform="translate(-312.077 -201.098)" fill="#2f2e41" />
              <ellipse id="Ellipse_18" data-name="Ellipse 18" cx="0.468" cy="1.169" rx="0.468" ry="1.169" transform="translate(436.226 61.868) rotate(-35.559)" fill="#3f3d56" />
              <ellipse id="Ellipse_19" data-name="Ellipse 19" cx="0.468" cy="1.169" rx="0.468" ry="1.169" transform="translate(434.822 59.997) rotate(-35.559)" fill="#3f3d56" />
            </g>
            <g id="Flow-2" data-name="Flow" transform="translate(10.25 -4052.926)">
              <g id="Line" transform="translate(0 -417)">
                <line id="Line_152" data-name="Line 152" y2={455} transform="translate(719.793 4998.5)" fill="none" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5} />
                <line id="Line_172" data-name="Line 172" y1={30} transform="translate(719.793 5541.5)" fill="none" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5} />
                <line id="Line_173" data-name="Line 173" x2="121.396" transform="translate(720.25 5571.5)" fill="none" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5} />
                <line id="Line_174" data-name="Line 174" x1={25} transform="translate(695.25 5658.426)" fill="none" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5} />
                <line id="Line_175" data-name="Line 175" y2={54} transform="translate(841.646 5571.5)" fill="none" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5} />
                <line id="Line_183" data-name="Line 183" y1="131.926" transform="translate(1194.646 5052.5)" fill="none" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5} />
                <line id="Line_184" data-name="Line 184" y1={375} transform="translate(1276.646 5196.426)" fill="none" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5} />
                <line id="Line_192" data-name="Line 192" x1="29.146" transform="translate(1247.5 5464)" fill="none" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5} />
                <line id="Line_193" data-name="Line 193" y1={68} transform="translate(780.948 5275.5)" fill="none" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5} opacity="0.3" />
                <line id="Line_193-2" data-name="Line 193" y1="392.926" transform="translate(1021.948 5343.5)" fill="none" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5} opacity="0.3" />
                <line id="Line_193-3" data-name="Line 193" x2="36.302" transform="translate(1021.948 5464)" fill="none" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5} opacity="0.3" />
                <line id="Line_194" data-name="Line 194" x2={495} transform="translate(781.25 5343.5)" fill="none" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5} opacity="0.3" />
                <g id="Group_2" data-name="Group 2" transform="translate(-124 -422.074)">
                  <line id="Line_197" data-name="Line 197" x2={42} transform="translate(1219.5 6023.5)" fill="none" stroke="#5c5c5c" strokeWidth={2} opacity="0.2" />
                  <line id="Line_198" data-name="Line 198" x2={42} transform="translate(1219.5 6120.5)" fill="none" stroke="#5c5c5c" strokeWidth={2} opacity="0.2" />
                  <line id="Line_199" data-name="Line 199" y1={97} transform="translate(1219.5 6023.5)" fill="none" stroke="#5c5c5c" strokeWidth={2} opacity="0.2" />
                  <line id="Line_200" data-name="Line 200" x1="116.25" transform="translate(1103.25 6080.5)" fill="none" stroke="#5c5c5c" strokeWidth={2} opacity="0.2" />
                </g>
              </g>
              <g id="Admin-2" data-name="Admin" transform="translate(-164.776 -7152.812)">
                <rect id="Rectangle_298" data-name="Rectangle 298" width={179} height={43} rx="21.5" transform="translate(1307.776 11718.812)" />
                <text id="Admin_Dashboard" data-name="Admin Dashboard" transform="translate(1336.211 11744.812)" fill="#fff" fontSize={14} fontFamily="AvenirNext-Bold, Avenir Next" fontWeight={700}><tspan x={0} y={0}>Admin Dashboard</tspan></text>
              </g>
              <g id="User_Balance" data-name="User Balance" transform="translate(572 3924.476)">
                <g id="Rectangle_45" data-name="Rectangle 45" transform="translate(571 784.524)" fill="#fff" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5}>
                  <rect width={255} height={62} rx={25} stroke="none" />
                  <rect x={1} y={1} width={253} height={60} rx={24} fill="none" />
                </g>
                <text id="User_Balance-2" data-name="User Balance" transform="translate(655.943 820.524)" fill="#383838" fontSize={14} fontFamily="AvenirNext-Medium, Avenir Next" fontWeight={500}><tspan x={0} y={0}>User </tspan><tspan y={0} fontFamily="AvenirNext-Bold, Avenir Next" fontWeight={700}>Balance</tspan></text>
              </g>
              <g id="User-2" data-name="User" transform="translate(-707.5 -7214.283)">
                <rect id="Rectangle_298-2" data-name="Rectangle 298" width={141} height={43} rx="21.5" transform="translate(1330 11719)" />
                <text id="User-3" data-name="User" transform="translate(1385.586 11745)" fill="#fff" fontSize={14} fontFamily="AvenirNext-Bold, Avenir Next" fontWeight={700}><tspan x={0} y={0}>User</tspan></text>
              </g>
              <text id="User_Can_be_Login_or_Register" data-name="User Can be Login  or Register" transform="translate(797 4521.217)" fill="#383838" fontSize={16} fontFamily="AvenirNext-Medium, Avenir Next" fontWeight={500} letterSpacing="0.03em"><tspan x={0} y={0}>Connect </tspan><tspan y={0} fontFamily="AvenirNext-Bold, Avenir Next" fontWeight={700}>Web 3</tspan><tspan fontFamily="AvenirNext-Bold, Avenir Next" fontWeight={700}><tspan x={0} y={20}>Wallet</tspan></tspan></text>
              <g id="KYC" transform="translate(104.611 4253.075)">
                <g id="Rectangle_45-2" data-name="Rectangle 45" transform="translate(571 785)" fill="#fff" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5}>
                  <rect width={255} height={62} rx={25} stroke="none" />
                  <rect x={1} y={1} width={253} height={60} rx={24} fill="none" />
                </g>
                <text id="Completes_KYC_Security" data-name="Completes KYC/Security" transform="translate(616.492 821)" fill="#383838" fontSize={14} fontFamily="AvenirNext-Medium, Avenir Next" fontWeight={500}><tspan x={0} y={0}>Fix the </tspan><tspan y={0} fontFamily="AvenirNext-Bold, Avenir Next" fontWeight={700}>Slippage Tolerance</tspan></text>
              </g>
              <text id="Two-Factor_Authentication_2FA_Mail_SMS" data-name="Two-Factor Authentication  2FA Mail/SMS" transform="translate(739 4815.121)" fill="#383838" fontSize={16} fontFamily="AvenirNext-Medium, Avenir Next" fontWeight={500} letterSpacing="0.03em"><tspan x={0} y={0}>Select the </tspan><tspan fontFamily="AvenirNext-Bold, Avenir Next" fontWeight={700}><tspan x={0} y={22}>Token to Swap</tspan></tspan></text>
              <text id="Two-Factor_Authentication_2FA_Reset" data-name="Two-Factor Authentication  2FA Reset" transform="translate(1311 4921.5)" fill="#383838" fontSize={16} fontFamily="AvenirNext-Medium, Avenir Next" fontWeight={500} letterSpacing="0.03em"><tspan x={0} y={0}>Pair</tspan><tspan fontFamily="AvenirNext-Bold, Avenir Next" fontWeight={700}><tspan x={0} y={22}>Management</tspan></tspan></text>
              <g id="Admin_Disputes" data-name="Admin Disputes" transform="translate(543 4448.5)">
                <circle id="Ellipse_134" data-name="Ellipse 134" cx="7.5" cy="7.5" r="7.5" transform="translate(689 591)" fill="#ff6464" />
                <g id="Group">
                  <text id="Manage_Liquidity" data-name="Manage Liquidity" transform="translate(533.75 593)" fill="#383838" fontSize={16} fontFamily="AvenirNext-Medium, Avenir Next" fontWeight={500} letterSpacing="0.03em"><tspan x={0} y={0}>Manage Liquidity</tspan></text>
                  <text id="Pool_Pairs" data-name="Pool Pairs" transform="translate(588.75 613)" fill="#383838" fontSize={16} fontFamily="AvenirNext-Bold, Avenir Next" fontWeight={700} letterSpacing="0.03em"><tspan x={0} y={0}>Pool Pairs</tspan></text>
                </g>
              </g>
              <g id="Complete_Trade" data-name="Complete Trade" transform="translate(572 4613.429)">
                <g id="Rectangle_45-3" data-name="Rectangle 45" transform="translate(577.75 542.497)" fill="#fff" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5}>
                  <rect width={255} height={62} rx={25} stroke="none" />
                  <rect x={1} y={1} width={253} height={60} rx={24} fill="none" />
                </g>
                <text id="Complete_Trade-2" data-name="Complete Trade" transform="translate(643.75 578.497)" fill="#383838" fontSize={14} fontFamily="AvenirNext-Medium, Avenir Next" fontWeight={500}><tspan x={0} y={0}>Add / Delete </tspan><tspan y={0} fontFamily="AvenirNext-Bold, Avenir Next" fontWeight={700}>Pools</tspan></text>
              </g>
              <g id="Complete_Trade-3" data-name="Complete Trade" transform="translate(572 4705.429)">
                <g id="Rectangle_45-4" data-name="Rectangle 45" transform="translate(577.75 542.571)" fill="#fff" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5}>
                  <rect width={255} height={62} rx={25} stroke="none" />
                  <rect x={1} y={1} width={253} height={60} rx={24} fill="none" />
                </g>
                <text id="Complete_Trade-4" data-name="Complete Trade" transform="translate(615.75 578.571)" fill="#383838" fontSize={14} fontFamily="AvenirNext-Medium, Avenir Next" fontWeight={500}><tspan x={0} y={0}>Manage Swap </tspan><tspan y={0} fontFamily="AvenirNext-Bold, Avenir Next" fontWeight={700}>Transactions</tspan></text>
              </g>
              <g id="User_Dashboard" data-name="User Dashboard" transform="translate(-133 4424.429)">
                <g id="Rectangle_45-5" data-name="Rectangle 45" transform="translate(570.75 784.571)" fill="#fff" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5}>
                  <rect width={255} height={62} rx={25} stroke="none" />
                  <rect x={1} y={1} width={253} height={60} rx={24} fill="none" />
                </g>
                <text id="User_Dashboard-2" data-name="User Dashboard" transform="translate(603.75 820.571)" fill="#383838" fontSize={14} fontFamily="AvenirNext-Medium, Avenir Next" fontWeight={500}><tspan x={0} y={0}>Token Received to the </tspan><tspan y={0} fontFamily="AvenirNext-Bold, Avenir Next" fontWeight={700}>Wallet</tspan></text>
              </g>
              <g id="My_Profile" data-name="My Profile" transform="translate(152 4424.429)">
                <g id="Rectangle_45-6" data-name="Rectangle 45" transform="translate(570.75 784.571)" fill="#fff" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5}>
                  <rect width={255} height={62} rx={25} stroke="none" />
                  <rect x={1} y={1} width={253} height={60} rx={24} fill="none" />
                </g>
                <text id="My_Profile-2" data-name="My Profile" transform="translate(631.75 820.571)" fill="#383838" fontSize={14} fontFamily="AvenirNext-Medium, Avenir Next" fontWeight={500}><tspan x={0} y={0}>Completion of </tspan><tspan y={0} fontFamily="AvenirNext-Bold, Avenir Next" fontWeight={700}>Swap</tspan></text>
              </g>
              <g id="Dot">
                <circle id="Ellipse_134-2" data-name="Ellipse 134" cx="7.5" cy="7.5" r="7.5" transform="translate(712 4571)" fill="#4b9aff" />
                <circle id="Ellipse_1363" data-name="Ellipse 1363" cx="7.5" cy="7.5" r="7.5" transform="translate(712 5113)" fill="#4b9aff" />
                <circle id="Ellipse_1367" data-name="Ellipse 1367" cx="7.5" cy="7.5" r="7.5" transform="translate(1271 4777)" fill="#ff6464" />
                <circle id="Ellipse_1368" data-name="Ellipse 1368" cx="7.5" cy="7.5" r="7.5" transform="translate(1186 4624)" fill="#ff6464" />
              </g>
              <g id="Group_1" data-name="Group 1" transform="translate(488.75 24.925)">
                <line id="Line_176" data-name="Line 176" y2="136.926" transform="translate(532.646 5299.5)" fill="none" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5} />
                <line id="Line_176-2" data-name="Line 176" y2="103.926" transform="translate(572.646 5538.5)" fill="none" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5} />
                <line id="Line_176-3" data-name="Line 176" y2="103.926" transform="translate(973.427 5436.5)" fill="none" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5} />
                <line id="Line_176-4" data-name="Line 176" y2="103.926" transform="translate(950.5 5642.5)" fill="none" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5} />
                <line id="Line_176-5" data-name="Line 176" y2={99} transform="translate(602.646 5746.426)" fill="none" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5} />
                <line id="Line_178" data-name="Line 178" x1="45.854" transform="translate(602.646 5845.5)" fill="none" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5} />
                <line id="Line_179" data-name="Line 179" x1="45.854" transform="translate(602.646 5744.5)" fill="none" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5} />
                <line id="Line_179-2" data-name="Line 179" x1="45.854" transform="translate(904.646 5744.5)" fill="none" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5} />
                <line id="Line_180" data-name="Line 180" x1="76.25" transform="translate(572.25 5642.5)" fill="none" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5} />
                <line id="Line_180-2" data-name="Line 180" x1="45.854" transform="translate(904.646 5642.5)" fill="none" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5} />
                <line id="Line_181" data-name="Line 181" x1="45.854" transform="translate(572.646 5538.5)" fill="none" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5} />
                <line id="Line_181-2" data-name="Line 181" x1="45.854" transform="translate(927.573 5538.5)" fill="none" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5} />
                <line id="Line_182" data-name="Line 182" x1="116.25" transform="translate(532.25 5436.5)" fill="none" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5} />
                <line id="Line_182-2" data-name="Line 182" x1="68.604" transform="translate(904.646 5436.5)" fill="none" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5} />
                <g id="Rectangle_45-7" data-name="Rectangle 45" transform="translate(647.611 5407)" fill="#fff" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5}>
                  <rect width={255} height={62} rx={25} stroke="none" />
                  <rect x={1} y={1} width={253} height={60} rx={24} fill="none" />
                </g>
                <text id="Price_Chart" data-name="Price Chart" transform="translate(739.757 5443)" fill="#383838" fontSize={14} fontFamily="AvenirNext-Medium, Avenir Next" fontWeight={500}><tspan x={0} y={0}>Liquidity </tspan><tspan y={0} fontFamily="AvenirNext-Bold, Avenir Next" fontWeight={700}>Pool</tspan></text>
                <g id="Rectangle_45-8" data-name="Rectangle 45" transform="translate(619.75 5509)" fill="#fff" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5}>
                  <rect width={311} height={62} rx={25} stroke="none" />
                  <rect x={1} y={1} width={309} height={60} rx={24} fill="none" />
                </g>
                <text id="Wallet_Balance_Deposit_Withdraw" data-name="Wallet Balance Deposit/Withdraw" transform="translate(711.75 5545)" fill="#383838" fontSize={14} fontFamily="AvenirNext-Medium, Avenir Next" fontWeight={500}><tspan x={0} y={0}>Add </tspan><tspan y={0} fontFamily="AvenirNext-Bold, Avenir Next" fontWeight={700}>New Liquidity</tspan></text>
                <g id="Rectangle_45-9" data-name="Rectangle 45" transform="translate(647.75 5611)" fill="#fff" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5}>
                  <rect width={255} height={62} rx={25} stroke="none" />
                  <rect x={1} y={1} width={253} height={60} rx={24} fill="none" />
                </g>
                <text id="View_Ads_Buy_Sell" data-name="View Ads Buy/Sell" transform="translate(685.75 5647)" fill="#383838" fontSize={14} fontFamily="AvenirNext-Medium, Avenir Next" fontWeight={500}><tspan x={0} y={0}>Select Pair to </tspan><tspan y={0} fontFamily="AvenirNext-Bold, Avenir Next" fontWeight={700}>Add Liquidity</tspan></text>
                <g id="Rectangle_45-10" data-name="Rectangle 45" transform="translate(633.25 5713)" fill="#fff" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5}>
                  <rect width={285} height={62} rx={25} stroke="none" />
                  <rect x={1} y={1} width={283} height={60} rx={24} fill="none" />
                </g>
                <text id="Post_Ads_Buy_Sell" data-name="Post Ads Buy/Sell" transform="translate(658.75 5749)" fill="#383838" fontSize={14} fontFamily="AvenirNext-Medium, Avenir Next" fontWeight={500}><tspan x={0} y={0}>Connect Wallet and </tspan><tspan y={0} fontFamily="AvenirNext-Bold, Avenir Next" fontWeight={700}>Deposit Tokens</tspan></text>
                <g id="Rectangle_45-11" data-name="Rectangle 45" transform="translate(647.75 5815)" fill="#fff" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5}>
                  <rect width={255} height={62} rx={25} stroke="none" />
                  <rect x={1} y={1} width={253} height={60} rx={24} fill="none" />
                </g>
                <text id="User_To_User_Message" data-name="User To User  Message" transform="translate(677.75 5851)" fill="#383838" fontSize={14} fontFamily="AvenirNext-Medium, Avenir Next" fontWeight={500}><tspan x={0} y={0}>Earn Trade fees from</tspan><tspan y={0} xmlSpace="preserve" fontFamily="AvenirNext-Bold, Avenir Next" fontWeight={700}> Liquidity</tspan></text>
                <circle id="Ellipse_1364" data-name="Ellipse 1364" cx="7.5" cy="7.5" r="7.5" transform="translate(525 5292)" fill="#4b9aff" />
              </g>
            </g>
          </g>
        </svg>
      </div>
    </section>
  )
}

export default HowOur